import React, { useState, useEffect } from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import { config } from '../../config';
import './Generatepage.css';
import shareicon from '../../assets/share_video.svg';
import deleteicon from '../../assets/delete_video.svg';
import downloadicon from '../../assets/download_video.svg';
import ReactPlayer from 'react-player';

const Generatepage = ({ result, onBack, setIsLoading, topic_type }) => {
  const [videoUrl, setVideoUrl] = useState('');  // Holds the video URL or object URL for the video Blob
  const [deleteResult, setDeleteResult] = useState('');  // To track delete result (success/failure)
  const [deletingVideoId, setDeletingVideoId] = useState(null);  // To track the video being deleted
  const [showDeletePopup, setShowDeletePopup] = useState(false);  // To control delete popup visibility
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const loadingMessages = [
    "Generating Video...",
    "Loading...",
    "Please wait..."
  ];

  useEffect(() => {
    logEvent(analytics, 'Generation_page_opened', {
      user_action: 'Generate_button Clicked'
    });

    // Show the loader until the video URL is set
    if (result) {
      setIsLoading(true); // Show loader when video URL starts loading
      const vidURL = result.result;  // Assuming result.result contains the video URL
      const cleanedUrl = vidURL.replace(/^"|"$/g, '');  // Clean URL

      // Set video URL after receiving the result
      setVideoUrl(cleanedUrl);

      // Stop the loader when video URL is ready
      setIsLoading(false);
    }
    else{
      setIsLoading(false);
      onBack();
    }
  }, [result, setIsLoading]);

  useEffect(() => {
    // Cycle through loading messages
    const interval = setInterval(() => {
      setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 1500); // Change message every 1.5 seconds

    return () => clearInterval(interval);
  }, []);

  const handleDelete = () => {
    setDeletingVideoId(videoUrl);  // Set the video URL being deleted
    setShowDeletePopup(true);  // Show the delete confirmation popup
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`${config.baseURL}/delete_video.php`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          videoId: deletingVideoId, 
          userId: localStorage.getItem('userId'),
        }), // Send the video URL to backend
      });

      const result = await response.json();

      if (result.success) {
        setDeleteResult('success');
        setTimeout(() => {
          setShowDeletePopup(false);
          setDeleteResult('');
          onBack();       
        }, 1000);  // Show success result in the popup
      } else {
        setDeleteResult('failure'); // Show failure result in the popup
      }
    } catch (error) {
      setDeleteResult('failure'); // Show failure result in the popup
    }
  };

  const cancelDelete = () => {
    setShowDeletePopup(false); // Simply close the popup without doing anything
    setDeleteResult(''); // Clear any previous delete result
  };

  const handleShare = async (videoUrl) => {
    try {
      const contentToShare = {
        title: `Create Stunning AI-Generated Videos with Ai Video Generator! 🚀`, // Promoting the AI-driven video creation
        text: `Experience the future of content creation! Watch this AI-generated video now and see how Ai Video Generator transforms your ideas into reality. 🌟 Don't miss out on the magic of AI! ${videoUrl}`,
        url: videoUrl, // Share the actual URL of the video directly // Share the actual URL directly if needed
      };

      if (navigator.share) {
        // Sharing the content
        await navigator.share(contentToShare);
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      alert('Sharing failed. Please try again.');
    }
  };


  const handleDownload = async () => {
    try {
  
      // Fetch the video file as a Blob (this should work even if it's partial content)
      const response = await fetch(videoUrl, {
        method: 'GET',
        headers: {
          'Accept': 'video/mp4',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch the video');
      }
  
      // Convert the response to a Blob
      const videoBlob = await response.blob();
  
      // Create a temporary link element to trigger the download
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(videoBlob); // Create a URL for the Blob
      a.href = url;
      a.download = 'AiVideogenerator.mp4';  // Change the filename if needed
      document.body.appendChild(a);
      a.click();
      
      // Clean up the URL object and remove the link element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the video:', error);
    }
  };



  return (
    <div className="generate-page-container">
      {/* Show loader or video */}
      {videoUrl === '' && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-message">{loadingMessages[loadingMessageIndex]}</div>
        </div>
      )}

      {/* Back button container */}
      <div className="generate-topdiv">
        <div className="backbtntext">
          <button className="back-button" onClick={onBack}>
            <img src={config.Backbutton} alt="Back" />
          </button>
        </div>
      </div>

      {/* Title and buttons container */}
      <div className="title-box_generatepage">
        <span className="title-genearate">
          Faceless Video Generator
        </span>
        <span className="button-container_generatepage">
          <button className="action-button_generatepage" onClick={handleDelete}>
            <img src={deleteicon} alt="" className='btn_img_generatepage' />            
          </button>
          <button className="action-button_generatepage" onClick={(e) => { e.stopPropagation(); handleShare(videoUrl); }}>
            <img src={shareicon} alt="" className='btn_img_generatepage' />
          </button> 
          <button className="action-button_generatepage" onClick={handleDownload}>
            <img src={downloadicon} alt="" className='btn_img_generatepage' />            
          </button>
        </span>
      </div>

      {/* Instruction text */}
      <div className="instruction-text_generatepage">
        For best results make sure you provide detailed instructions.
      </div>

      {/* Video container */}
      {videoUrl !== '' && (
        <ReactPlayer url={videoUrl} controls width="80%" height="20%" style={{ borderRadius: '20px', overflow: "hidden", aspectratio:'16/9'}} />
      )}

      {/* Delete confirmation popup */}
      {showDeletePopup && (
        <div className="delete-popup_generatepage">
          <div className="delete-popup_generatepage_white">
            <p>Are you sure you want to delete this video?</p>
            <button onClick={confirmDelete}>Yes, Delete</button>
            <button onClick={cancelDelete}>Cancel</button>

            {deleteResult && (
              <div className={`delete-result ${deleteResult}`}>
                {deleteResult === 'success' ? 'Video deleted successfully.' : 'Failed to delete video.'}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Generatepage;
